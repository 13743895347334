<template>
  <div class="height-100">
    <div class="flex-cell rows" style="justify-content: space-between" v-if="!loading">
      <a v-if="card" :href="getCardUrl(card)" class="text-xl search-item-link" @click.stop.prevent="cardClickHandler(card)">
        <span>{{ card.name | shortString }}</span
        >&nbsp;<span v-if="confidence > 0">({{ confidence | floatToPercent }})</span>
      </a>
      <div class="search-item-text--large" v-else>{{ $tfo('not_found,,2') }}</div>

      <div v-if="card && card.watch_lists" class="watch-lists-container">
        <span
          class="card-list-item text-s"
          :style="{ backgroundColor: '#' + list.color, color: getFontColor(list) }"
          v-for="list in getWatchlists(card.watch_lists)"
        >
          {{ list.name | shortString }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Color from 'color';
import Styles from '@/guide/components/styles';
import { humanCardsModule } from '../../../store';

export default {
  name: 'card-table-part-item',
  components: { Styles },
  props: {
    byCardFaceId: {
      type: String
    },
    byCardId: {
      type: Number
    },
    confidence: {
      type: Number
    }
  },
  data() {
    return {
      loading: false,
      card: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    getFontColor(item) {
      let color = Color('#' + item.color),
        isLight = color.light();
      return isLight ? '#000' : 'rgba(255, 255, 255, 0.8)';
    },
    getWatchlists(v) {
      return v.map((id) => this.$store.state.watch_lists.items.find((v) => v.id === id));
    },
    load() {
      let getCardIdPromise = null;
      this.loading = true;

      if (this.byCardFaceId) {
        getCardIdPromise = this.$store.dispatch('getBatchObjectFace', { id_in: this.byCardFaceId }).then((v) => v.card);
      } else {
        getCardIdPromise = Promise.resolve(this.byCardId);
      }

      getCardIdPromise
        .then((v) => {
          return humanCardsModule.getById({ id: v });
        })
        .then((card) => {
          this.card = card;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCardUrl(v) {
      return '#/cards/humans/' + v.id;
    },
    cardClickHandler(v) {
      this.$router.push({ path: '/cards/humans/' + v.id });
    }
  }
};
</script>

<style lang="stylus">

.watch-lists-container {
  margin-top: 1rem;
}

.card-list-item {
  display: inline-block;
  margin-right: 0.5rem;
  background-color: gray;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 4px;
}
</style>
