<template>
  <div>
    <div class="content-header cols">
      <div>
        <h2>{{ $tfo('search_results') }}</h2>
      </div>
    </div>

    <el-row class="cols--center mar-v--1">
      <div class="cell"></div>

      <div name="table-pagination" class="table-pagination pull-right mar-v--1">
        <table-navigation :state="state"></table-navigation>
      </div>
    </el-row>

    <el-table :name="model.name + '-table'" :class="model.name + '-table'" stripe :size="$vars.sizes.table || $vars.sizes.common" :data="state.items">
      <el-table-column prop="id" :label="$tf('id')" width="60"></el-table-column>
      <el-table-column prop="thumbnail" :label="$tf('common.detected')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail :url="row.thumbnail || ''" :frame="row.fullframe || ''" @preview="(v) => showImage(row.fullframe, getBox(row))"></object-thumbnail>
        </template>
      </el-table-column>
      <el-table-column prop="match" :label="$tf('common.matched_to')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail v-if="row.face_object_id" :id="row.face_object_id" @preview="(v) => showImage(v && v.src, null)"></object-thumbnail>
          <object-thumbnail v-if="row.case_face_id" :case-face-id="row.case_face_id" @preview="(v) => showImage(v && v.src, null)"></object-thumbnail>
        </template>
      </el-table-column>

      <el-table-column prop="name" :label="$tf('name')" min-width="200px">
        <template slot-scope="{ row }">
          <card-table-part-item
            v-if="row.card"
            :key="'item' + row.card"
            :confidence="row.confidence"
            :by-card-id="row.card"
          ></card-table-part-item>
          <div v-else-if="row.case_face_id">
            <div>{{ $tfo('case_face_id') }}: {{ row.case_face_id }} ({{ $filters.floatToPercent(row.confidence) }})</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="date" width="120px" :label="$tf('date')">
        <template slot-scope="{ row }">
          {{ row.external_timestamp | parseDate | formatDate }}<br />
          {{ row.external_timestamp | parseDate | formatTime }}
        </template>
      </el-table-column>

      <el-table-column prop="dates" width="120px" :label="$tf('camera')">
        <template slot-scope="{ row }">
          {{ row.camera }}
          <br />
          <span class="text-tetriary">
            {{ row.puppet_id }}
          </span>
        </template>
      </el-table-column>
    </el-table>

    <div name="table-pagination" class="table-pagination pull-right mar-v--1">
      <table-navigation :state="state"></table-navigation>
    </div>
  </div>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import ObjectThumbnail from '../../objects/thumbnail.vue';
import CardTablePartItem from '@/components/puppeteer/search-events/card.item';
import qs from 'qs';

export default {
  name: 'page-search-events',
  components: { CardTablePartItem, ObjectThumbnail, TableNavigation },
  data: function () {
    return {
      model: {
        name: 'search-events',
        route: 'puppeteer/search-events',
        permissions: {
          create: 'ffsecurity.add_search-events'
        }
      },
      card: null
    };
  },
  computed: {
    state() {
      return this.$store.state.puppeteer_search_events;
    }
  },
  mounted() {},
  methods: {
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    getItemLink(item) {
      return `/${this.model.route}/` + encodeURIComponent(item.id) + '/';
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
      });
      return false;
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    },
    getBox(v) {
      return {
        x: v.bbox.left,
        y: v.bbox.top,
        w: v.bbox.right - v.bbox.left,
        h: v.bbox.bottom - v.bbox.top
      };
    },
    getCaseUrl(caseFace) {
      return `#/cases/${caseFace.case}/faces/filter/` + qs.stringify({ case_in: [caseFace.case], limit: 10 });
    }
  }
};
</script>
